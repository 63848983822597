import React from "react";
import "./Landing.css";
import { Card } from "react-bootstrap";
import gambar from "../Component/landing3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowTurnRight,
  faCheckCircle,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import landing1 from "../Component/landing1.png";
import Footer from "../Component/Footer/Footer";
import Carousel2 from "../Component/Carousel2/Carousel2";

const Landing = () => {
  return (
    <React.Fragment>
      <div className="box-landing">
        <div className="content-desk">
          <div>
            <h1 className="title-utama">
              Bimbel Masuk Kedokteran, PTN & Kedinasan 2023
            </h1>
            <p className="deks-landing">
              Bimbel Persiapan UTBK SNBT / SBMPTN, Simak UI, UTUL UGM, UM Undip
              dan Ujian Mandiri PTN. Edumatrix Indonesia - Bimbel Sukses Masuk
              FK, PTN & Kedinasan Terbaik.
            </p>
            <a href="https://api.whatsapp.com/send?phone=+6281215523902&text=Halo%20Kak%2C%20Saya%20mau%20tanya%20mengenai%20bimbel%20diEdumatrix%20Terima%20kasih.">
            <button className="btn-landing">
              <FontAwesomeIcon className="icon-landing" icon={faWhatsapp} />{" "}
              <a
                className="text-button"
                href="https://api.whatsapp.com/send?phone=+6281215523902&text=Halo%20Kak%2C%20Saya%20mau%20tanya%20mengenai%20bimbel%20diEdumatrix%20Terima%20kasih."
              >
                Hubungi Kami Sekarang
              </a>
            </button>
            </a>
          </div>
          <img className="img-landing" src={gambar} alt="" />
        </div>
      </div>

      <div className="parent-detail">
        <div className="box-detail">
          <div className="icon">
            <span className="icon-detail">
              <img
                className="icon-detail"
                src="https://cdn-icons-png.flaticon.com/512/5064/5064943.png"
                alt=""
              />
              <p className="desk-icon">
                <strong>Siswa Belajar</strong>
                <br />
                <span style={{ color: "rgb(0, 149, 218)" }}>
                  <strong>12.000+</strong>
                </span>
              </p>
            </span>
            <span className="icon-detail">
              <img
                className="icon-detail"
                src="https://cdn-icons-png.flaticon.com/512/1995/1995574.png"
                alt=""
              />
              <p className="desk-icon">
                <strong>Tutor Edumatrix Indonesia</strong>
                <br />
                <span style={{ color: "rgb(0, 149, 218)" }}>
                  <strong>5.000+</strong>
                </span>
              </p>
            </span>
            <span className="icon-detail">
              <img
                className="icon-detail"
                src="https://cdn-icons-png.flaticon.com/512/4697/4697984.png"
                alt=""
              />
              <p className="desk-icon">
                <strong>Siswa Berhasil</strong>
                <br />
                <span style={{ color: "rgb(0, 149, 218)" }}>
                  <strong>95%</strong>
                </span>
              </p>
            </span>
            <span className="icon-detail">
              <img
                className="icon-detail"
                src="https://cdn-icons-png.flaticon.com/128/10015/10015050.png"
                alt=""
              />
              <p className="desk-icon">
                <strong>
                  Tingkat Kepuasan <br />
                  Siswa
                </strong>
                <br />
                <span style={{ color: "rgb(0, 149, 218)" }}>
                  <strong>9/10</strong>
                </span>
              </p>
            </span>
          </div>
        </div>
      </div>

      <div className="welcome">
        <div className="content-welcome">
          <div className="parent-desk">
            <div className="parent-logoedu">
              <img className="img-logoedu" src="https://edumatrix-indonesia.com/wp-content/uploads/2022/12/logo-header-600x377.png" alt="" />
            </div>
            <h1 className="welcome-text">Welcome to Edumatrix Indonesia</h1>
            <p className="desk-welcome">
              <strong>Supercamp EDUMATRIX</strong> adalah program bimbingan
              belajar dengan <strong>sistem karantina.</strong> Kami hadir
              sebagai partner terbaik bagi siswa untuk menyediakan program
              bimbingan masuk UI, ITB, UGM, IPB, Unair, ITS, Undip, Unpad &
              Perguruan Tinggi Negeri Favorit. <br />
            </p>
          </div>
        </div>
        <img className="img-landing2" src={landing1} alt="" />
      </div>

      <div className="box-pengajar">
        <div className="content-pengajar">
          <h1 className="title-pengajar">Our Professional Teachers</h1>
          <p className="desk-pengajar">
            <strong>Supercamp EDUMATRIX</strong>
            {" "}
            diselenggarakan oleh Alumni Universitas Indonesia yang merupakan
            praktisi bimbel dengan ribuan siswa di Jabodetabek dan berbagai kota
            besar Indonesia. Kami hadir sebagai solusi untuk kebutuhan bimbingan
            belajar yang fokus untuk menghadapi{" "}
            <strong>UTBK SNBT/SBMPTN</strong> dan Ujian Mandiri seperti{" "}
            <strong>Simak UI, UTUL UGM, UTM IPB, SMUP Unpad</strong> dan
            lainnya.
          </p>
          <div className="univ">
            <img
              className="img-univ"
              src="https://edumatrix-indonesia.com/wp-content/uploads/2022/12/Group-560.png"
              alt=""
            />
          </div>
        </div>

        <div className="box-progedu">
          <h1 className="prog-unggul">
            Program Unggulan Edumatrix Indonesia 2023
          </h1>
          <div className="card-pengajar">
            {/* 1 */}
            <Card className="main-card">
              <Card.Img
                className="img-card"
                variant="top"
                src="https://edumatrix-indonesia.com/wp-content/uploads/2023/01/bimbel-masuk-kedokteran-400x426.jpeg"
              />
              <Card.Body>
                <Card.Title className="text-card">
                  <strong>Program Supercamp Kedokteran 2023</strong>
                </Card.Title>
              </Card.Body>
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Supercamp%20Garansi%20Priority%20Masuk%20Kedokteran%202023.%20Mohon%20info%20selengkapnya%20...">
              <button className="btn-card">
                <strong>
                  <a
                    className="chatus-card"
                    href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Supercamp%20Garansi%20Priority%20Masuk%20Kedokteran%202023.%20Mohon%20info%20selengkapnya%20..."
                  >
                    Chat Us Now!
                  </a>
                </strong>
              </button>
              </a>
            </Card>
            {/* 2 */}
            <Card className="main-card">
              <Card.Img
                className="img-card"
                variant="top"
                src="https://edumatrix-indonesia.com/wp-content/uploads/2023/01/bimbel-masuk-ptn-400x428.jpeg"
              />
              <Card.Body>
                <Card.Title className="text-card">
                  <strong>Program Supercamp Sukses PTN 2023</strong>
                </Card.Title>
              </Card.Body>
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Supercamp%20Garansi%20Priority%20Masuk%20PTN%202023.%20Mohon%20info%20selengkapnya%20...">
              <button className="btn-card">
                <strong>
                  <a
                    className="chatus-card"
                    href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Supercamp%20Garansi%20Priority%20Masuk%20PTN%202023.%20Mohon%20info%20selengkapnya%20..."
                  >
                    Chat Us Now!
                  </a>
                </strong>
              </button>
              </a>
            </Card>
            {/* 3 */}
            <Card className="main-card">
              <Card.Img
                className="img-card"
                variant="top"
                src="https://edumatrix-indonesia.com/wp-content/uploads/2023/01/les-privat-snbt-400x427.jpeg"
              />
              <Card.Body>
                <Card.Title className="text-card">
                  <strong>
                    Kelas Intensif Sukses SNBT <br />
                    2023
                  </strong>
                </Card.Title>
              </Card.Body>
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Kelas%20Intensif,%20Program%20Sukses%20Masuk%20FK%20&%20PTN%202023.%20Mohon%20info%20selengkapnya%20...">
              <button className="btn-card">
                <strong>
                  <a
                    className="chatus-card"
                    href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Kelas%20Intensif,%20Program%20Sukses%20Masuk%20FK%20&%20PTN%202023.%20Mohon%20info%20selengkapnya%20..."
                  >
                    Chat Us Now!
                  </a>
                </strong>
              </button>
              </a>
            </Card>
            {/* 4 */}
            <Card className="main-card">
              <Card.Img
                className="img-card"
                variant="top"
                src="https://edumatrix-indonesia.com/wp-content/uploads/2023/01/les-privat-utbk-400x427.jpeg"
              />
              <Card.Body>
                <Card.Title className="text-card">
                  <strong>Privat UTBK 2023 Online & Offline</strong>
                </Card.Title>
              </Card.Body>
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Les%20Privat,%20Program%20Sukses%20Masuk%20FK%20dan%20PTN%202023.%20Mohon%20info%20selengkapnya%20...">
              <button className="btn-card">
                <strong>
                  <a
                    className="chatus-card"
                    href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Les%20Privat,%20Program%20Sukses%20Masuk%20FK%20dan%20PTN%202023.%20Mohon%20info%20selengkapnya%20..."
                  >
                    Chat Us Now!
                  </a>
                </strong>
              </button>
              </a>
            </Card>
          </div>
        </div>
      </div>

      <div className="semua-bimbel">
        <h1 className="title-bimbel">Semua Bimbingan Belajar</h1>
        <div className="box-listbimbel">
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel Privat TK, SD, SMP, & SMA
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Les Privat Mahasiswa Nasional & Internasional
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Les Privat Agama Islam
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Privat Komputer & IT
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Les Privat Musik
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Les Privat Persiapan UTBK SNBT/SBMPTN
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Les Privat Bahasa Asing
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel Kedokteran
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel GapYear
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel UMPTN
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel UMPTKIN
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel Akpol
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel Akmil
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel Sekolah Kedinasan
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Privat OSN untuk SD
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Privat OSN untuk SMP
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Privat OSN untuk SMA
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel OSN (SD, SMP, SMA)
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel IUPI (KKI)
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel LPDP
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Bimbel PPDS
          </li>
          <li className="list-bimbel">
            <FontAwesomeIcon className="icon-listbimbel" icon={faCheckCircle} />{" "}
            Internasional School Student
          </li>
        </div>
      </div>

      <div className="bimbel-sekolah">
        <h1 className="title-bimbelsekolah">
          Program Les Privat TK, SD, SMP, SMA, UN/AKM, OSN, Simak UI, SBMPTN,
          Mahasiswa
        </h1>
        <p className="desk-bimskol">
          Berikut adalah program unggulan yang dirancang untuk Anda agar proses
          belajar semakin Efektif dan Efisien.
          Pilih program belajar Edumatrix Indonesia lainnya yang paling kamu
          butuhkan saat ini.
        </p>
        <div className="parent-bimskol">
          <div className="card-bimbelsekolah">
            {/* 1 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Program%20Belajar%20Privat%20TK%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/TK-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Program Belajar Privat TK 2023</strong>
              </Card.Text>
            </Card>
            {/* 2 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Program%20Belajar%20Privat%20SD%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/SD-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Program Belajar Privat SD 2023</strong>
              </Card.Text>
            </Card>
            {/* 3 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20Program%20Belajar%20Privat%20SMP%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/SMP-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Program Belajar Privat SMP 2023</strong>
              </Card.Text>
            </Card>
            {/* 4 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Les%20Privat,%20Program%20Belajar%20Private%20SMA%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/SMA-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Program Belajar Privat SMA 2023</strong>
              </Card.Text>
            </Card>
          </div>

          <div className="card-bimbelsekolah">
            {/* 5 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Bimbel%20Privat%20Bahasa%20Asing%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/Bahasa-Asing-1-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Bimbel Privat Bahasa Asing 2023</strong>
              </Card.Text>
            </Card>
            {/* 6 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Tutor%20for%20International%20School%20Students%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/Mahasiswa-Nasional-dan-Internasional-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Tutor for International School Students</strong>
              </Card.Text>
            </Card>
            {/* 7 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Les%20Privat%20TK,%20SD,%20SMP%20dan%20SMA.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/TK-SD-SMP-SMA-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Les Privat TK, SD, SMP & SMA</strong>
              </Card.Text>
            </Card>
            {/* 8 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Bimbel%20Privat%20OSN%20SD%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/02/OSN-SD-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Bimbel Privat OSN SD</strong>
              </Card.Text>
            </Card>
          </div>

          <div className="card-bimbelsekolah">
            {/* 9 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Program%20Training%20Camp%20AKPOL%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/AKPOL-1-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Program Training Camp AKPOL 2023</strong>
              </Card.Text>
            </Card>
            {/* 10 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Program%20Training%20Camp%20AKMIL%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/AKMIL-1-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Program Training Camp AKMIL 2023</strong>
              </Card.Text>
            </Card>
            {/* 11 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Program%20Training%20Camp%20Sekolah%20Kedinasan%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/Kedinasan-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Program Training Camp Sekolah Kedinasan 2023</strong>
              </Card.Text>
            </Card>
            {/* 12 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Bimbel%20Tes%20CPNS%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/CPNS-2-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Bimbel Tes CPNS 2023</strong>
              </Card.Text>
            </Card>
          </div>

          <div className="card-bimbelsekolah">
            {/* 13 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Program%20Private%20OSN%20SMP%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/02/OSN-SMP-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Program Privat OSN SMP 2023</strong>
              </Card.Text>
            </Card>
            {/* 14 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Program%20Private%20OSN%20Matematika%20SD%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/Matematika-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Bimbel Privat OSN Matematika untuk SD</strong>
              </Card.Text>
            </Card>
            {/* 15 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Bimbel%20Privat%20OSN%20IPA%20untuk%20SD%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/IPA-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Bimbel Privat OSN IPA untuk SD</strong>
              </Card.Text>
            </Card>
            {/* 16 */}
            <Card className="main-bimskol">
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Program%20Private%20OSN%20SMA%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelsekolah"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/02/OSN-SMA-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Program Privat OSN SMA 2023</strong>
              </Card.Text>
            </Card>
          </div>
        </div>
      </div>

      <div className="bimbel-khusus">
        <h1 className="title-bimkhus">Program Bimbingan Kursus Untuk Umum</h1>
        <p className="desk-bimkhus">
          Berikut adalah program unggulan yang dirancang untuk Anda agar proses
          belajar semakin Efektif dan Efisien. <br />
          Pilih program belajar Edumatrix Indonesia lainnya yang paling kamu
          butuhkan saat ini.
        </p>

        <div className="parent-bimkhus">
          <div className="card-bimbelkhusus">
            {/* 1 */}
            <Card className="main-bimkhus">
              <a href="https://web.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Eka,%20saya%20ingin%20daftar%20Program%20Les%20Privat%20Komputer%20dan%20IT%20basic..%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelkhusus"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/Les-Privat-komputer-Basic-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Bimbel Privat Komputer Basic</strong>
              </Card.Text>
            </Card>
            {/* 2 */}
            <Card className="main-bimkhus">
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Bimbel%20Privat%20Bahasa%20Asing%202023.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelkhusus"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/Bahasa-Asing-1-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Bimbel Privat Bahasa Asing 2023</strong>
              </Card.Text>
            </Card>
            {/* 3 */}
            <Card className="main-bimkhus">
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Aulia,%20saya%20ingin%20daftar%20Program%20Les%20Privat%20Gitar.%20Mohon%20info%20selengkapnya%20...">
                <Card.Img
                  className="img-bimbelkhusus"
                  variant="top"
                  src="https://edumatrix-indonesia.com/wp-content/uploads/2023/03/Les-Privat-Gitar-400x400.jpg"
                ></Card.Img>
              </a>
              <Card.Text className="text-bimskol">
                <strong>Bimbel Privat Gitar</strong>
              </Card.Text>
            </Card>
          </div>
        </div>
      </div>

      <div className="keunggulan-edu">
        <h1 className="title-keunggulan">Keunggulan Edumatrix Indonesia</h1>
        <div className="parent-unggul">
          <div className="card-unggul">
            {/* 1 */}
            <Card className="main-unggul">
              <Card.Img
                className="img-unggul"
                variant="top"
                src="https://cdn-icons-png.flaticon.com/512/1995/1995574.png"
              ></Card.Img>
              <Card.Title className="text-unggul">
                <strong>Pengajar Muda & Berpengalaman</strong>
              </Card.Title>
              <Card.Text className="desk-keunggulan">
                EDUMATRIX INDONESIA hanya memilih tenaga pengajar terbaik untuk
                melatih setiap siswanya. Setiap tenaga pengajar yang dipilih
                tergolong masih muda dan sangat berpengalaman di bidangnya. Jadi
                bisa dipastikan, setiap siswa akan mendapatkan pembelajaran
                terbaik dari ahlinya.
              </Card.Text>
            </Card>
            {/* 2 */}
            <Card className="main-unggul">
              <Card.Img
                className="img-unggul"
                variant="top"
                src="https://cdn-icons-png.flaticon.com/512/993/993697.png"
              ></Card.Img>
              <Card.Title className="text-unggul">
                <strong>
                  Metode Belajar <br />
                  Personal
                </strong>
              </Card.Title>
              <Card.Text className="desk-keunggulan">
                Metode Belajar yang digunakan yaitu one on one (1 siswa 1
                mentor). Program belajar didesain secara sistematis,
                terstruktur, terukur, dan teruji. Pembelajaran Tematik berdasar
                Statistik Soal yang diujikan. Fokus menerapkan Pola Sukses yang
                sudah proven.
              </Card.Text>
            </Card>
            {/* 3 */}
            <Card className="main-unggul">
              <Card.Img
                className="img-unggul"
                variant="top"
                src="https://cdn-icons-png.flaticon.com/512/870/870139.png"
              ></Card.Img>
              <Card.Title className="text-unggul">
                <strong>
                  Bahan Ajar & <br />
                  Soal Lengkap
                </strong>
              </Card.Title>
              <Card.Text className="desk-keunggulan">
                Modul belajar lengkap, Sistematis, dan Prediktif terhadap
                soal-soal yang kemungkinan besar akan muncul di tes. Selain itu
                juga dilengkapi dengan Try Out Progresif untuk mengukur tingkat
                keberhasilan siswa.
              </Card.Text>
            </Card>
            {/* 4 */}
            <Card className="main-unggul">
              <Card.Img
                className="img-unggul"
                variant="top"
                src="https://cdn-icons-png.flaticon.com/512/4697/4697984.png"
              ></Card.Img>
              <Card.Title className="text-unggul">
                <strong>
                  Tingkat Kelulusan <br />
                  Tinggi
                </strong>
              </Card.Title>
              <Card.Text className="desk-keunggulan">
                Dengan program yang proven, didesain secara sistematis,
                terstruktur, terukur, dan teruji, kami siap memberikan suport
                terbaik untuk siswa. Komitmen kami yaitu memberikan layanan
                terbaik agar siswa dapat meraih target yaitu lulus tes.
              </Card.Text>
            </Card>
          </div>
          <div className="card-unggul">
            {/* 5 */}
            <Card className="main-unggul">
              <Card.Img
                className="img-unggul"
                variant="top"
                src="https://cdn-icons-png.flaticon.com/512/3429/3429440.png"
              ></Card.Img>
              <Card.Title className="text-unggul">
                <strong>
                  5000+ Guru <br />
                  Privat
                </strong>
              </Card.Title>
              <Card.Text className="desk-keunggulan">
                Edumatrix Indonesia bukan hanya memiliki tenaga pengajar yang
                berkualitas tapi dari segi kuantitas juga bisa diandalkan. Di
                mana Edumatrix Indonesia memiliki 5000 lebih guru privat
                profesional yang tersebar di seluruh Indonesia.
              </Card.Text>
            </Card>
            {/* 6 */}
            <Card className="main-unggul">
              <Card.Img
                className="img-unggul"
                variant="top"
                src="https://cdn-icons-png.flaticon.com/512/8065/8065183.png"
              ></Card.Img>
              <Card.Title className="text-unggul">
                <strong>
                  Guru Privat <br />
                  Terbaik
                </strong>
              </Card.Title>
              <Card.Text className="desk-keunggulan">
                Tenaga pengajar yang dimiliki oleh Edumatrix Indonesia tidaklah
                sembarangan. Namun tenaga pengajar yang terpilih dari berbagai
                PTN terbaik di Indonesia. Kami percaya bahwa kesuksesan siswa
                selalu dimulai dari tenaga pengajar yang kompeten, profesional,
                dan pastinya berkualitas.
              </Card.Text>
            </Card>
            {/* 7 */}
            <Card className="main-unggul">
              <Card.Img
                className="img-unggul"
                variant="top"
                src="https://cdn-icons-png.flaticon.com/512/1575/1575412.png"
              ></Card.Img>
              <Card.Title className="text-unggul">
                <strong>
                  Bimbel <br />
                  Terlengkap
                </strong>
              </Card.Title>
              <Card.Text className="desk-keunggulan">
                Kami percaya bahwa setiap siswa pastinya memiliki kebutuhan
                belajar yang berbeda-beda. Menjawab kebutuhan tersebut,
                Edumatrix Indonesia menyiapkan berbagai program belajar yang
                bisa dipilih sesuai kebutuhan siswa. Hal inilah yang membuat
                Edumatrix Indonesia adalah Bimbel terlengkap dan terbaik.
              </Card.Text>
            </Card>
            {/* 8 */}
            <Card className="main-unggul">
              <Card.Img
                className="img-unggul"
                variant="top"
                src="https://cdn-icons-png.flaticon.com/512/3750/3750020.png"
              ></Card.Img>
              <Card.Title className="text-unggul">
                <strong>
                  Siswa Bebas <br />
                  Memilih Guru
                </strong>
              </Card.Title>
              <Card.Text className="desk-keunggulan">
                Pembelajaran yang berhasil jelas tidak bisa dilepaskan dari
                kesinambungan antara guru dan siswa. Faktor kecocokan inilah
                yang membuat proses belajar menjadi lebih menyenangkan. Sehingga
                Edumatrix Indonesia membebaskan siswa untuk memilih guru yang
                sesuai dengan kebutuhan dan referensi belajarnya.
              </Card.Text>
            </Card>
          </div>
        </div>
      </div>

      <div className="box-testimoni">
        <h1 className="title-testimoni">Testimoni</h1>
        <div className="slider-testi">
          {/* <Slider /> */}
          <Carousel2 />
        </div>
      </div>

      <div className="fasilitas-program">
        <h1 className="title-fasilitas">Fasilitas Program</h1>
        <div className="list-fasilitas">
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Supercamp berlangsung di tempat yang nyaman, bersih dan sehat,
            sangat menunjang dalam membangun Efek Positif dan menambah Motivasi
            Belajar.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Lingkungan yang aman, nyaman, dikelola secara Profesional dan
            memiliki Fasilitas Terlengkap.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Ruang Kamar standar Plus dengan Fasilitas Meja Belajar, 1 Tempat
            Tidur, TV dan Kulkas.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Penyediaan Konsumsi selama Program Super Camp Berlangsung (makan 3
            Kali sehari dan 1 Kali Snack).
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Disediakan face shield, masker, hand sanitizer, hand wash dan
            vitamin.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Try Out Progresif Setiap Minggu dengan pembahasan pada sesi belajar.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Intensive Quiz pada setiap sesi belajar.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Modul belajar lengkap, Sistematis dan Prediktif terhadap soal-soal
            UTBK SNBT / SBMPTN / Simak UI / UTUL UGM dan Ujian Mandiri PTN.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Buku-Buku Materi dan Soal-soal.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Alat-alat Tulis.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Analisa dan Evaluasi Hasil Belajar setiap hari.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Laporan dan Evaluasi Hasil Belajar setiap minggu.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Setiap Siswa Dipantau 24 jam oleh Koordinator pembimbing
            masing-masing siswa-siswi.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Motivation Training Program untuk menambah semangat belajar siswa.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Garansi Penggantian Pengajar Jika merasa Tidak Cocok.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Layanan Laundry.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Free Formulir UTBK dan Simak UI/UTUL UGM/UM Undip (Regular).
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Antar Jemput ke Lokasi Ujian (Jabodetabek).
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Bimbingan dan Arahan Strategi Belajar Efektif.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Bimbingan Informasi Universitas dan Jurusan Favorit.
          </li>
          <li className="li-fasilitas">
            <FontAwesomeIcon
              className="icon-fasilitas"
              icon={faArrowTurnRight}
            />{" "}
            Dibimbing sampai dengan diterima PTN.
          </li>
        </div>
      </div>

      <div className="pro-teacher">
        <div className="img-proteach">
          <img
            className="img-teach"
            src="https://edumatrix-indonesia.com/wp-content/uploads/2022/12/Group-561.jpg"
            alt=""
          />
        </div>
      </div>

      <div className="success-story">
        <div className="list-story">
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UI Internasional
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Hukum UI Internasional
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UGM Internasional
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Hukum UGM Internasional
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Managemen UGM Internasional
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            HI UGM Internasional
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Akuntansi Undip Internasional
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Farmasi ITB Internasional
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Sipil ITS Internasional
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Kimia ITS Internasional
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UGM
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FKG UGM
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FKH UGM
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FKG UB
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UNDIP
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FKG UNAIR
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UNPAD
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FKG UNPAD
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UNSOED
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Hukum UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UNSYIAH
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UIN Jakarta
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UIN Jakarta
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UNHAS
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UNSRI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UNLAM
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK UNAND
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FK Universitas Palangkaraya
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Psikologi UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Akuntansi UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Gizi UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Metalurgi UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Ekonomi UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            STEI ITBTeknik Industri UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            FTTM ITB
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            SBM ITB
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Arsitektur ITB
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Industri dan Pertanian IPB
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Biokimia IPB
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Managemen UB
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Statistik UB
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Hukum UB
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Informatika UNDIP
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Hukum UNDIP
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Akuntansi UNAIR
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Psikologi UNPAD
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Elektro ITS
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Perkapalan ITS
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Informatika ITS
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Informatika UPN Veteran Jakarta
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Industri UNS
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Hukum USU
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            PGSD UNJ
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Ilmu Ekonomi UGM
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Akuntansi UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Ilmu Ekonomi UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Komunikasi UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Arsitektur UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Arsitektur Unair
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Industri UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Hukum UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Psikologi UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Fasilkom UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Matematika UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Biologi UI
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Akuntansi UGM
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Geofisika ITB
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Tek Geofisika ITS
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Tek Perkapalan ITS
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            Teknik Geologi UNDIP
          </li>
          <li className="li-story">
            <FontAwesomeIcon className="icon-story" icon={faArrowTurnRight} />{" "}
            dan PTN Terbaik lainnya
          </li>
        </div>
        <div>
          <h1 className="title-story">Success Stories</h1>
          <p className="desk-story">
            Tim kami telah membantu siswa diterima di berbagai jurusan berikut :
          </p>
        </div>
      </div>

      <div className="box-alumni">
        <h1 className="title-alumni">Ikuti Jejak Sukses Alumni</h1>
        <p className="desk-alumni">
          Saat ini adalah waktu yang tepat untuk memulai perjuangan. Dengan
          berbekal kesungguhan dan kerja keras kita ikuti jejak alumni yang
          telah sukses masuk PTN Favorit Indonesia.
        </p>

        <div className="group-img">
          <img
            className="alumni-img"
            src="https://edumatrix-indonesia.com/wp-content/uploads/2022/12/siswa-bimbel-snbt.jpg"
            alt=""
          />
          <img
            className="alumni-img"
            src="https://edumatrix-indonesia.com/wp-content/uploads/2022/12/siswa-bimbel-snbt-2.jpg"
            alt=""
          />
          <img
            className="alumni-img"
            src="https://edumatrix-indonesia.com/wp-content/uploads/2022/12/siswa-bimbel-snbt-3.jpg"
            alt=""
          />
          <img
            className="alumni-img"
            src="https://edumatrix-indonesia.com/wp-content/uploads/2022/12/siswa-bimbel-snbt-4.jpg"
            alt=""
          />
        </div>
      </div>

      <div className="end-qout">
        <h3>TUNGGU APALAGI !</h3>
        <h1 className="title-quot">Ayo Daftar Edumatrix Sekarang !</h1>
        <div className="all-btnend">
          <a href="https://api.whatsapp.com/send?phone=+6281215523902&text=Halo%20Kak%2C%20Saya%20mau%20tanya%20mengenai%20bimbel%20diEdumatrix%20Terima%20kasih.">
          <button className="btn-viawa">
            <a
              href="https://api.whatsapp.com/send?phone=+6281215523902&text=Halo%20Kak%2C%20Saya%20mau%20tanya%20mengenai%20bimbel%20diEdumatrix%20Terima%20kasih."
              className="a-viawa"
            >
              <FontAwesomeIcon className="icon-viawa" icon={faWhatsapp} />{" "}
              Hubungi Kami Via WhatsApp
            </a>
          </button>
          </a>
          <a href="tel:+6281215523902">
          <button className="btn-viahp">
            <a href="tel:+6281215523902" className="a-viahp">
              <FontAwesomeIcon className="icon-viahp" icon={faPhone} /> Hubungi
              Kami Via Telepon
            </a>
          </button>
          </a>
        </div>
        <p className="desk-quot">
          Silahkan Hubungi Kami Untuk Mendapatkan Informasi Terbaik Sesuai
          Kebutuhan Anda.
        </p>
      </div>

      <Footer />

      <div className="box-wa">
      <a href="https://api.whatsapp.com/send?phone=+6281215523902&text=Halo%20Kak%2C%20Saya%20mau%20tanya%20mengenai%20bimbel%20diEdumatrix%20Terima%20kasih.">
        <button className="btn-wa">
          <a
            className="wa"
            href="https://api.whatsapp.com/send?phone=+6281215523902&text=Halo%20Kak%2C%20Saya%20mau%20tanya%20mengenai%20bimbel%20diEdumatrix%20Terima%20kasih."
          >
            <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
          </a>
        </button>
        </a>
        <a href="tel:+6281215523902">
        <button className="btn-call">
          <a href="tel:+6281215523902" className="call">
            <FontAwesomeIcon icon={faPhone} /> Telepon
          </a>
        </button>
        </a>
      </div>
    </React.Fragment>
  );
};

export default Landing;
