import './App.css';
import Landing from './Landing/Landing';

function App() {
  return (
   <Landing />
  );
}

export default App;
